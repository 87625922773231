<template>
<div :style="computedStyle" v-show="!isHidden" :class="CSSClasses">
  {{isEditor() ? $locale.main.button.choose_file : ''}}
    <el-upload
      v-show="allowAdding"
      ref="upload"
      :action="`${$config.api}/registryservice/attachments`"
      :file-list="files"
      :headers="headers"
      :multiple="true"
      :name="name"
      :data="parameters"
      :on-change="numberFiles"
      :on-preview="download"
      :before-remove="beforeRemove"
      :auto-upload="true">
      <el-button v-show="!(_isReadonly || (!getRegistryRecordId() || !getRegistryId()))" style="display: none" slot="trigger" size="small" type="primary">{{ $locale.main.button.choose_file }}</el-button>
    </el-upload>
  <div v-if="render" v-show="slider" class="box_sliders">
    <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
      <div class="slide" v-for="(slide, index) in slides" :key="index" ><img :alt="index" :src="slide"/></div>
    </agile>
    <agile class="thumbnails" v-show="slides.length > 1" ref="thumbnails" :options="options2" :as-nav-for="asNavFor2">
      <div class="slide slide--thumbnail" v-for="(slide, index) in slides" :key="index" @click="goTo(index)">
        <img :alt="index" :src="slide"/></div>
      <template slot="prevButton"><i class="el-icon-arrow-left"></i></template>
      <template slot="nextButton"><i class="el-icon-arrow-right"></i></template>
    </agile>
  </div>
  <slot></slot>
</div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import { VueAgile } from 'vue-agile'

export default {
  name: 'file_field',
  props: {
    slider: {
      type: Boolean,
      description: 'slider',
      default: false
    },
    allowAdding: {
      type: Boolean,
      description: 'allow_adding',
      default: true
    },
    autoplay: {
      type: Boolean,
      description: 'auto_play',
      default: true
    },
    openCardInWindow: {
      type: Boolean,
      frozen: true
    },
    isRequired: {
      frozen: true
    }
  },
  mixins: [mixin, registryMixin],
  inject: {
    getRegistryRecordId: {
      default: () => () => null
    },
    getRegistryId: {
      default: () => () => null
    }
  },
  components: {
    agile: VueAgile
  },
  data () {
    return {
      files: [],
      count: true,
      render: false,
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        fade: true,
        navButtons: false
      },

      options2: {
        autoplay: true,
        pauseOnHover: true,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: 2,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2
            }
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true
            }
          }
        ]
      },
      slides: [
        'https://tsum.by/upload/no-photo.png'
      ],
      headers: {
        Authorization: localStorage.getItem('user-token')
      }
    }
  },
  watch: {
    slider: {
      handler: function (val) {
        // console.log('slider', val)
        if (val) this.forceRerender()
      },
      immediate: true
    },
    value () {
      this.parseValue()
    }
  },
  computed: {
    parameters () {
      return {
        record_id: this.getRegistryRecordId(),
        registry_id: this.getRegistryId()
      }
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  methods: {
    goTo (index) {
      if (this.count) {
        this.count = false
        this.asNavFor1.push(this.$refs.thumbnails)
        this.asNavFor2.push(this.$refs.main)
      }
      this.$refs.thumbnails.goTo(index)
    },
    numberFiles (file, fileList) {
      if (file.response && this.slider) {
        this.slides.push(`${this.$config.api}/files/${file.response.guid}.${file.name.split('.')[1]}`)
        this.forceRerender()
      }
    },
    download (file) {
      if (file.raw) {
        this.downloadBlob(file.raw, file.name)
      } else if (file.url) {
        let me = this
        this.$http({
          method: 'get',
          url: file.url,
          responseType: 'blob'
        }).then(function (response) {
          let blob = new Blob([response.data])
          me.downloadBlob(blob, file.name)
        })
      }
    },
    downloadBlob (blob, filename) {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    forceRerender () {
      this.render = false
      this.asNavFor1 = []
      this.asNavFor2 = []
      this.options2.slidesToShow = this.slides.length
      this.options2.responsive[0].settings.slidesToShow = this.slides.length
      if (!this.autoplay) {
        this.options2.autoplay = this.autoplay
      }
      // setTimeout нужен если слайдер на другой вкладке
      setTimeout(() => {
        this.$nextTick().then(() => {
          this.render = true
          if (this.$refs.thumbnails) {
            // console.log('setTimeout thumbnails', this.$refs.thumbnails)
            this.asNavFor1.push(this.$refs.thumbnails)
            this.asNavFor2.push(this.$refs.main)
          }
        })
      }, 2000)
    },
    async beforeRemove (file) {
      if (!file.id) {
        return false
      }
      if (this.openCardInWindow) {
        let confirmRemoveFile = confirm(this.$locale.main.message.delete)
        if (confirmRemoveFile) {
          return this.$http.delete(`${this.$config.api}/registryservice/attachments/${file.id}`)
        }

        return Promise.reject(new Error('Отмена'))
      }

      let answer = await this.$confirm(
        this.$locale.main.message.confirm,
        this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        })
      if (answer === 'confirm') {
        return this.$http.delete(`${this.$config.api}/registryservice/attachments/${file.id}`)
      }
    },
    parseValue () {
      if (!this.value || this.isEditor() || this.value.length === 0) {
        return false
      }
      let parsed = JSON.parse(this.value)
      if (parsed instanceof Array && parsed.length > 0) {
        this.files = parsed.map(file => {
          return {
            name: `${file.name}.${file.extension}`,
            url: `${this.$config.api}/files/${file.directory ? file.directory + '/' : ''}${file.guid}.${file.extension}`,
            id: file.id
          }
        })
        this.slides = []
        this.slides = parsed.map(file => `${this.$config.api}/files/${file.directory ? file.directory + '/' : ''}${file.guid}.${file.extension}`)
      }
      if (this.slider) this.forceRerender()
    }
  },
  mounted () {
    this.parseValue()
  }
}
</script>

<style src="./style/slider.css"></style>
